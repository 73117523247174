@import "@core/scss/core.scss";
@import "./assets/scss/styles";
@import "~ngx-toasta/styles/style-bootstrap.css";

@import "./assets/styles/ngx-datatable.scss";
@import "@core/scss/angular/libs/datatables.component.scss";

@import "@core/scss/angular/libs/quill-editor.component.scss";
@import "@core/scss/angular/libs/select.component.scss";
@import "@core/scss/angular/libs/flatpickr.component.scss";

@import "@core/scss/angular/libs/form-wizard.component.scss";

@import "./assets/styles/custom-vendor";
// @import '~bootstrap/scss/bootstrap';

@import "./assets/styles/vertical-tabs";
@import "./assets/styles/ngx-datatable";
@import "./assets/styles/styles-vendor.css";
//@import './assets//scss/normal.css';
//@import './assets/scss/leaflet-panel-layers.css';
//@import './assets/scss/leaflet-geoman.css';
//@import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

:root {
  --primary-color: #ffffff;
  --secondary-color: #ffff8c;
}

// we can also define variables with JavaScript

//$primary:#0070E1;
$primary: var(--primary-color);
$secondary: var(--secondary-color);

.progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;

  .container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    -webkit-transform: translate(0, 0) scale(1, 1);
    transform: translate(0, 0) scale(1, 1);
    background-color: rgb(170, 209, 249);

    .bar {
      transition: all 0.2s linear;
      -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
      animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
      transition: -webkit-transform 0.2s linear;
      transition: transform 0.2s linear;
      background-color: rgb(16, 108, 200);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
    }
  }

  .truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* in-flight clone */
  .gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
  }

  /* high-performance display:none; helper */
  .gu-hide {
    left: -9999px !important;
  }

  /* added to mirrorContainer (default = body) while dragging */
  .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  /* added to the source element while its mirror is dragged */
  .gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
  }
}

.markerDivIcon {
  text-align: center;
  /* Horizontally center the text (icon) */
  line-height: 20px;
  /* Vertically center the text (icon) */
}

.accordion .card .card-header button {
  background-color: white !important;
}

svg {
  fill: none !important;
}

.circle {
  background: #f00;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.ignitionOn {
  background: rgb(255, 159, 67);
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.moving {
  background: rgb(40, 199, 111);
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.ignitionOff {
  background: rgb(130, 134, 139);
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.offline {
  background: rgb(210, 213, 216);
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.div1 {
  border: 1px solid rgb(92, 92, 94);
}
.modal-backdrop {
  z-index: 0 !important;
}

.event-container {
  margin-left: 400px !important;
}

.theme-green .bs-datepicker-head {
  background-color: #d43042 !important;
}
.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #d43042 !important;
}
.theme-green .bs-datepicker-body table td.week span {
  color: #d43042 !important;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.assetArrowRed {
  border-radius: 50%;
  border: 3px solid #d43042;
  width: 40px;
  height: 40px;
}

.assetArrowRed::after {
  position: absolute;
  content: "";
  width: 0;
  top: 35px;
  height: 0;
  bottom: 0;
  left: 12px;
  border-width: 7px;
  border-style: solid;
  border-top-style: solid;
  border-top-width: 10px;
  border-top-color: #d43042;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.assetArrowOrange {
  border-radius: 50%;
  border: 3px solid #ff9f43;
  width: 40px;
  height: 40px;
}

.assetArrowOrange::after {
  position: absolute;
  content: "";
  width: 0;
  top: 35px;
  height: 0;
  bottom: 0;
  left: 12px;
  border-width: 7px;
  border-style: solid;
  border-top-style: solid;
  border-top-width: 10px;
  border-top-color: #ff9f43;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.assetArrowGrey {
  border-radius: 50%;
  border: 3px solid #82868b;
  width: 40px;
  height: 40px;
}

.assetArrowGrey::after {
  position: absolute;
  content: "";
  width: 0;
  top: 35px;
  height: 0;
  bottom: 0;
  left: 12px;
  border-width: 7px;
  border-style: solid;
  border-top-style: solid;
  border-top-width: 10px;
  border-top-color: #82868b;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.assetArrowGreen {
  border-radius: 50%;
  border: 3px solid #28c76f;
  width: 40px;
  height: 40px;
}

.assetArrowGreen::after {
  position: absolute;
  content: "";
  width: 0;
  top: 35px;
  height: 0;
  bottom: 0;
  left: 12px;
  border-width: 7px;
  border-style: solid;
  border-top-style: solid;
  border-top-width: 10px;
  border-top-color: #28c76f;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.assetArrowRed {
  border-radius: 50%;
  border: 3px solid #d43042;
  width: 40px;
  height: 40px;
}

.assetArrowRed::after {
  position: absolute;
  content: "";
  width: 0;
  top: 35px;
  height: 0;
  bottom: 0;
  left: 12px;
  border-width: 7px;
  border-style: solid;
  border-top-style: solid;
  border-top-width: 10px;
  border-top-color: #d43042;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
