//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Bootstrap variables
// $primary: #4186e0;

  // $primary: #5FB7D5
  // we can also define variables with JavaScript
  
  $primary:#d43042;

 