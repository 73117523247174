/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */





.dropdown{
	z-index: 1500 !important;
}

.customizer {
	left: -400px;
	margin-top: 59px;
     z-index: 400;
	bottom: 100;
	transition: left .4s cubic-bezier(.05,.74,.2,.99);
	right: auto;
	&.show{
	  left: 0;
	  right: auto;
	}
	.customizer-toggle {
	  border-top-right-radius: 6px;
	  border-bottom-right-radius: 6px;
	  border-top-left-radius: 0px;
	  border-bottom-left-radius: 0px;
	  right: -39px;
	  left: auto;
	  top: 30%;
	}
  }
  






  c-btn{
    width: 20px !important;
  }
// .header-navbar{
// 	background-color: #5FB7D5 !important;
// 	color: white !important;
// }



  .vl {
	border-left: 6px solid green;
	height: 500px;
  }

  .slider{
	  margin-top: 10px !important;
  }

  .trip_replay_control {
    background: whitesmoke;
    padding: 5px; 
	border: 1px solid #d43042 !important;
  }
  .map_control {
    background: whitesmoke;
    padding: 5px; 
	//margin-right: 200px !important;
	margin-top: 180px !important;
	border: 1px solid #d43042 !important;
  }

  .trip-display{
	  color: #d43042 !important;
	
  }
  .trip-display-buttons{
	  border-color: #d43042;
	  border: 1px solid #d43042;
	  cursor: pointer;
  }
.date-display{
	border-color: #d43042;
	border: 1px solid #d43042;
}

.loading-text{
    color:#D43042 !important;
}



